<template>
  <form
    class="flex w-full flex-col items-start space-y-2 px-9.5"
    @submit.prevent="onSignUp"
  >
    <div class="w-full">
      <div class="mb-1 font-medium capitalize">{{ $t("firstName") }}</div>
      <UiInput
        v-model="form.first_name"
        class="flex-1"
        name="first_name"
        placeholder="John"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium capitalize">{{ $t("lastName") }}</div>
      <UiInput
        v-model="form.last_name"
        class="flex-1"
        name="last_name"
        placeholder="Smith"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium capitalize">{{ $t("emailAddress") }}</div>
      <UiInput
        v-model="form.email"
        class="flex-1"
        name="email"
        :placeholder="$t('enterYourEmailAddress')"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium">{{ $t("password") }}</div>
      <UiInput
        v-model="form.password"
        class="flex-1"
        name="password"
        placeholder="••••••••••••"
        autocomplete="new-password"
        type="password"
      />
    </div>
    <div class="w-full">
      <div class="mb-1 font-medium">{{ $t("repeatPassword") }}</div>
      <UiInput
        v-model="form.password_confirmation"
        class="flex-1"
        name="password_confirmation"
        placeholder="••••••••••••"
        type="password"
      />
    </div>
    <UiButton
      :loading="isSubmitting"
      type="submit"
      color="primary"
      class="mb-4 w-full"
    >
      {{ $t("signUp") }}
    </UiButton>
    <div class="font-medium text-primary">
      {{ $t("alreadyHaveAnAccount") }}
      <button
        class="underline"
        type="button"
        @click="$emit('changeForm', AuthDrawerEnum.LOGIN)"
      >
        {{ $t("logIn") }}
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { object, ref, string } from "yup";
import { SignUpForm } from "@/types/api/auth.types";
import { authApi } from "@/api";
import { passwordRegexp } from "~/configs";
import { useGeneralStore } from "~/stores/general";
import { AuthDrawerEnum, AuthDrawerType } from "~/types/components.types";

const { t } = useI18n();
const router = useRouter();
const localePath = useLocalePathPolyfill();

const generalStore = useGeneralStore();

defineEmits<{
  (e: "changeForm", type: AuthDrawerType): void;
}>();

const { openSystemDialog } = useSystemDialogs();
const initialSignUpForm: SignUpForm = {
  first_name: "",
  last_name: "",
  password: "",
  password_confirmation: "",
  email: "",
};

const schemaSignUp = object().shape({
  email: string().email().required().label(t("emailAddress")),
  first_name: string().required().label(t("firstName")),
  last_name: string().required().label(t("lastName")),
  password: string()
    .min(8)
    .matches(passwordRegexp, {
      message: "Requirements: 1 capital letter, 1 number, 1 special character",
    })
    .required()
    .label(t("password")),
  password_confirmation: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required()
    .label(t("repeatPassword")),
});

const form = reactive<SignUpForm>(
  JSON.parse(JSON.stringify(initialSignUpForm)),
);

const {
  handleSubmit: handleSubmitSignUp,
  resetForm: resetFormSignUp,
  setErrors: setErrorsSignUp,
  isSubmitting,
} = useForm({
  initialValues: initialSignUpForm,
  validationSchema: schemaSignUp,
});

const onSignUp = handleSubmitSignUp(async (values) => {
  const { error } = await authApi.signUp(values);

  if (!error.value) {
    resetFormSignUp();
    openSystemDialog({
      type: "alert",
      title: `${t("success")}!`,
      text: `${t("confirmMessage")}.`,
      successHandler: async () => {
        generalStore.isSignInDrawerOpen = false;
        await router.push(localePath("/"));
      },
    });
  } else {
    const errors = responseErrorToObjectErrors(
      error?.value?.data?.errors,
      form,
    );
    if (!isEmpty(errors)) {
      setErrorsSignUp(errors);
    } else {
      openSystemDialog({
        type: "alert",
        title: `${t("error")}!`,
        text: error?.value?.data?.errors[0].message,
      });
    }
  }
});
</script>
